if (mc_module_module == 'CartController' || mc_module_module == 'CartController2') {
$(document).ready(function(){
	if (!mc_payment_methods_exists) {
		$('#delivery-section .delivery-select input:checked').trigger('click');
	}
	
	$('form#cart_form input[data-value-default], form#cart_form textarea[data-value-default]').each(function(){
		if ($(this).val().length == 0)
			$(this).val($(this).attr('data-value-default'));
	});
	
	//$("#phone").mask("+7 (999) 999-99-99", { autoclear: false });
	//$("#phone2").mask("+7 (999) 999-99-99", { autoclear: false });
	
	check_user_info_data(true, false);
	//update_price();
	
	//$('#delivery-section .delivery-select input:visible:first').click();
	var delivery_id = $('#delivery-section .delivery-select input:checked').val();
	$.get(mc_config_root_url + mc_module_url + '?action=get_payment_methods&delivery_id='+delivery_id, function(data){
		var new_items = $('<div class="payment-select"></div>');
		var show_online_alert_message = false;
		for(var index in data.data)
		{
			var d = data.data[index];
			if (d.id == 12)
				show_online_alert_message = true;
			if (d.operation_type == 'plus_fix_sum')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'minus_fix_sum')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'plus_percent')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			if (d.operation_type == 'minus_percent')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			var obj = $('<div class="payment-method '+(index==0?"active":"")+'" data-id="'+d.id+'"><div class="payment-method__inner"><div class="radio" data-type="'+d.operation_type+'" data-value="'+d.operation_value+'"><label class="payment-method__label"><input type="radio" name="payment_method_id" '+(index==0?"checked":"")+' value="'+d.id+'"/><div class="payment-image">'+(d.image.length>0?'<img src="'+d.image+'" />':'')+'</div><div class="payment-header">'+d.name+'</div></label></div></div></div>');
			new_items.append(obj);
			if (index == 0) {
				$('#selected-payment-method').html(d.name);
			}
		}
		$('#payment-section div.payment-select').replaceWith(new_items);
		if (mc_module_module == 'CartController2')
			if (show_online_alert_message)
				$('#online-alert-message').removeClass('hidden');
			else
				$('#online-alert-message').addClass('hidden');
		update_price();
	});
	
	$('textarea[name=comment],textarea[name=address]').autosize();
	
	$(".fancybox").fancybox({
		autoResize: true,
		autoCenter: true,
		prevEffect	: 'none',
		nextEffect	: 'none',
		helpers	: {
			title	: {
				type: 'outside'
			},
			thumbs	: {
				width	: 50,
				height	: 50
			},
			overlay : {
			   locked     : false
			}
		}
	});
	
	var suggestion;

	function initSuggestionField() {
		suggestion = $("#address").suggestions({
			token: "bb7c3b73ff8c4eae3d22919da090a5ab0787e0ec",
			type: "ADDRESS",
			onSelect: function(suggestion) {
				//console.log(suggestion);
			}
		});
	}
	initSuggestionField();
	
	$('#disable-address-autocomplete').click(function(){
		if(this.checked) {
			$('#address').suggestions('dispose');
			$('input[name=suggestion_enabled]').val('0');
		} else {
			initSuggestionField();
			$('input[name=suggestion_enabled]').val('1');
		}
	});
	
	//Dynamic remarketing myTarget Pro
	if (typeof _tmr != 'undefined' && mc_module_module == 'CartController') {
		_tmr.push({
			type: 'itemView',
			productid: mc_cart_variants_ids,
			pagetype: 'cart',
			list: '1',
			totalvalue: mc_cart_total_price
		});
		//console.log('send cart data to remarketing ok');
	}
});

$('#apply-promocode').click(function(){
	$.ajax({
		type: 'POST',
		url: '/cart/?action=apply_promocode',
		data: {
			promocode: $('#promocode').val()
		},
		error: function() {
			//error_box(obj, 'Ошибка при применении промокода!', '');
		},
		success: function(data) {
			//info_box(obj, 'Изменения сохранены!', '');
			if (data['success']) {
				if (data['promocode_result']) {
					$('#promocode-error-msg').toggleClass('text-danger').toggleClass('text-success');
					$('#promocode-error-msg').text('Промокод применен');
					location.reload();
				}
				else {
					$('#promocode-error-msg').text(data['promocode_error_msg']);
					$('#promocode-error-msg').show();
				}
			}
		}
	});
	return false;
});

$('#remove-promocode').click(function(){
	$.ajax({
		type: 'POST',
		url: '/cart/?action=remove_promocode',
		error: function() {
		},
		success: function(data) {
			if (data['success']) {
				location.reload();
			}
		}
	});
	return false;
});

$('input[name=buyer_type]').change(function(){
	var value = $(this).val();
	if (value == 'fiz')
		$('#yur-block').hide();
	else
		$('#yur-block').show();
	return false;
});

$("#phone_code,#phone2_code,#phone,#phone2").keydown(function(event) {
	if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 ||
		(event.keyCode == 65 && event.ctrlKey === true) ||
		(event.keyCode >= 35 && event.keyCode <= 39)) {
			 return;
	}
	else {
		if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
			event.preventDefault();
		}  
	}
});
$("#phone_code").keyup(function(){
	if ($(this).val().length == 3)
		$("#phone").focus();
});
$("#phone2_code").keyup(function(){
	if ($(this).val().length == 3)
		$("#phone2").focus();
});

$('#notify-order-on').click(function(){
	$('#notify-order-on').addClass('on');
	$('#notify-order-off').removeClass('off');
	$('#notify-order').val(1);
	return false;
});

$('#notify-order-off').click(function(){
	$('#notify-order-on').removeClass('on');
	$('#notify-order-off').addClass('off');
	$('#notify-order').val(0);
	return false;
});

$('#notify-news-on').click(function(){
	$('#notify-news-on').addClass('on');
	$('#notify-news-off').removeClass('off');
	$('#notify-news').val(1);
	return false;
});

$('#notify-news-off').click(function(){
	$('#notify-news-on').removeClass('on');
	$('#notify-news-off').addClass('off');
	$('#notify-news').val(0);
	return false;
});

$('#main-right-container a.incart').click(function(){
	var href = $(this).attr('href');
	var href_variant_id = $(this).attr('data-variant-id');
	var href_variant_amount = $(this).attr('data-variant-amount');
	var tr = $(this).closest('tr');
	var a_href = tr.find('td.carttable-product div.carttable-product-name a');
	$.get(href, function(data){
		if (data.success)
		{
			/*if ($('table.carttable tr td.carttable-product').length == 1)
			{
				//$('table.carttable').closest('div').html('Корзина пуста');
				$('table.carttable').closest('form').html('Корзина пуста');
			}
			else
			{*/
				tr.html('<td class="carttable-info" colspan="3">Товар '+a_href[0].outerHTML+' был удален из корзины.</td><td class="carttable-info" colspan="2"><a class="restore" href="/cart/?action=restore_purchase&variant_id='+href_variant_id+'&amount='+href_variant_amount+'" style="color:red;">Восстановить</a></td>');
				update_price();
			/*}*/
			update_cart_info();
			update_external_module_forms();
			
			if (data.total_products == 1 && !data.is_complect_containts) {
				$('#delivery-section input[name=delivery_id][value=9]').closest('div.delivery-select').show();
			} else {
				$('#delivery-section input[name=delivery_id][value=9]').closest('div.delivery-select').hide();
			}
		}
	});
	return false;
});

$('#main-right-container').on('click', 'a.restore', function(){
	var href = $(this).attr('href');
	$.get(href, function(data){
		if (data.success)
		{
			location.reload();
		}
	});
	return false;
});

$('#delivery-section .delivery-select input').click(function(){
	$('#delivery-section .delivery-select.active').removeClass('active');
	$(this).closest('.delivery-select').addClass('active');
	$('#selected-delivery-method').html($(this).attr('data-name'));
	
	if ($(this).attr('data-ext-module-name').length > 0)
	{
		var func_name = $(this).attr('data-ext-module-name') + '_select';
		// find object
		var fn = window[func_name];
		// is object a function?
		if (typeof fn === "function") fn();
	}
	
	/*$('#extModuleMkadKilometersMinus').prop('disabled', true);
	$('#extModuleMkadKilometers').prop('disabled', true);
	$('#extModuleMkadKilometersPlus').prop('disabled', true);
	
	if  ($(this).closest('.radio').find('#extModuleMkadKilometers').length > 0)
	{
		$('#extModuleMkadKilometersMinus').prop('disabled', false);
		$('#extModuleMkadKilometers').prop('disabled', false);
		$('#extModuleMkadKilometersPlus').prop('disabled', false);
	}*/
	
	if ($(this).data('pickup')) {
		$('.userdata textarea[name=address]')./*val('').*/closest('.form-group').hide();
		$('#map').hide();
	}else {
		$('.userdata textarea[name=address]')./*val('').*/closest('.form-group').show();
		$('#map').show();
	}
	
	if ($(this).data('deliverysettings'))
		$('#delivery-settings').show();
	else
		$('#delivery-settings').hide();

	var delivery_id = $('#delivery-section .delivery-select input:checked').val();
	$.get(mc_config_root_url + mc_module_url + '?action=get_payment_methods&delivery_id='+delivery_id, function(data){
		var new_items = $('<div class="payment-select"></div>');
		for(var index in data.data)
		{
			var d = data.data[index];
			if (d.operation_type == 'plus_fix_sum')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'minus_fix_sum')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + ' ' + mc_main_currency_sign + ')';
			if (d.operation_type == 'plus_percent')
				d.name += ' (+' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			if (d.operation_type == 'minus_percent')
				d.name += ' (-' + d.operation_value.replace(/0+$/,'').replace(/\.+$/,'') + '%)';
			var obj = $('<div class="payment-method '+(index==0?"active":"")+'" data-id="'+d.id+'"><div class="payment-method__inner"><div class="radio" data-type="'+d.operation_type+'" data-value="'+d.operation_value+'"><label class="payment-method__label"><input type="radio" name="payment_method_id" '+(index==0?"checked":"")+' value="'+d.id+'"/><div class="payment-image">'+(d.image.length>0?'<img src="'+d.image+'" />':'')+'</div><div class="payment-header">'+d.name+'</div></label></div></div></div>');
			new_items.append(obj);
			if (index == 0) {
				$('#selected-payment-method').html(d.name);
			}
		}
	
		//$('#payment-section .payment-method.active').removeClass('active');
		
		$('#payment-section div.payment-select').replaceWith(new_items);
	
		// $('#payment-section .payment-select').quicksand( new_items.find('div.payment-method'), { adjustHeight: 'dynamic', duration: 500 }, function(){
		// 	$('#payment-section .payment-select input:first').prop('checked', true);
		// 	$('#payment-section .payment-method:first').addClass('active');
		// 	/*update_price();*/
		// });
		
		update_price();
	});
});

$('#payment-section').on('click', '.payment-method input', function(){
	$('#payment-section .payment-method.active').removeClass('active');
	$(this).closest('.payment-method').addClass('active');
	$('#selected-payment-method').html($(this).closest('.payment-method').find('.payment-header').html());
	
	update_price();
});

$('#delivery-settings').on('change', 'select[name=delivery_time]', function(){
	update_price();
});

$('input[name=is_need_installation], input[name=is_need_gauger]').change(function(){
	var install_text = '';
	var is_need_installation = $('input[name=is_need_installation]').prop('checked');
	var is_need_gauger = $('input[name=is_need_gauger]').prop('checked');
	if (is_need_installation && is_need_gauger) {
		install_text = 'Необходим монтаж + Выезд замерщика';
	}
	if (is_need_installation && !is_need_gauger) {
		install_text = 'Необходим монтаж';
	}
	if (!is_need_installation && is_need_gauger) {
		install_text = 'Выезд замерщика';
	}
	$('#selected-installation').html(install_text);
	update_price();
});

$('table.carttable td.carttable-count input').bind('change keyup input click', function(){
	if (this.value.match(/[^0-9]/g)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	if (this.value < 0)
		this.value = 0;
	
	if (this.value == 0) {
		if (confirm("Удалить товар из корзины?")) {
			$(this).closest('tr').find('td.carttable-remove a.incart').click();
			return;
		} else {
			this.value = 1;
		}
	}
	
	set_amount($(this).attr('data-id'), this.value);
	update_price();
});

$('table.carttable td.carttable-count button[data-type=plus]').click(function(){
	var input = $(this).closest('td').find('input');
	var max_stock = input.data('stock');
	if (input.length > 0)
	{
		var value = parseInt(input.val()) + 1;
		if (value > max_stock)
			value = max_stock;
		input.val(value);
		set_amount(input.attr('data-id'), value);
		update_price();
	}
});

$('table.carttable td.carttable-count button[data-type=minus]').click(function(){
	var input = $(this).closest('td').find('input');
	if (input.length > 0)
	{
		var value = parseInt(input.val()) - 1;
		if (value < 0 )
			value = 0;
		
		if (value == 0) {
			if (confirm("Удалить товар из корзины?")) {
				$(this).closest('tr').find('td.carttable-remove a.incart').click();
				return;
			} else {
				value = 1;
			}
		}
		
		input.val(value);
		set_amount(input.attr('data-id'), value);
		update_price();
	}
});

function check_user_info_data(show_checks, show_errors){
	var result = true;
	if (show_checks == undefined)
		show_checks = false;
	if (show_errors == undefined)
		show_errors = false;
	var is_focused = false;

	$('.userdata input.required:visible, .userdata textarea.required:visible').each(function(){
		var type = $(this).attr('data-type');
		if (type.length == 0)
			type = "text";
		var show_error = false;
		switch(type){
			case "text":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "phone":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					/*if ($(this).mask().length == 0)
					{
						show_error = true;
						result = false;
					}*/
					if ($(this).val().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$('#cart-phone-required').show();
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "number":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					{
						show_error = true;
						result = false;
					}
				});
				
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "email":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if (!isValidEmailAddress($(this).val()))
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
		}
	});
	
	/*Дополнительная проверка email (если поле заполнено)*/
	var show_error = false;
	if ($('input[name=email]').length > 0 && $('input[name=email]').val().length > 0 && !isValidEmailAddress($('input[name=email]').val())) {
		show_error = true;
		result = false;
	}
	if (show_error)
	{
		$('input[name=email]').closest('div.row').find('.help-block-error').show();
		$('input[name=email]').closest('div.form-group').find('.fa-check').hide();
		if (!is_focused) {
			$('input[name=email]').focus();
			is_focused = true;
		}
	}
	else
		if ($('input[name=email]').length > 0 && isValidEmailAddress($('input[name=email]').val())) {
			$('input[name=email]').closest('div.row').find('.help-block-error').hide();
			$('input[name=email]').closest('div.form-group').find('.fa-check').show();
		}
	
	return result;
}

function check_user_info_data_in_modal(modal_id, show_checks, show_errors){
	var result = true;
	if (show_checks == undefined)
		show_checks = false;
	if (show_errors == undefined)
		show_errors = false;
	var is_focused = false;

	$(modal_id + ' .userdata input.required:visible, ' + modal_id + ' .userdata textarea.required:visible').each(function(){
		var type = $(this).attr('data-type');
		if (type.length == 0)
			type = "text";
		var show_error = false;
		switch(type){
			case "text":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "phone":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					/*if ($(this).mask().length == 0)
					{
						show_error = true;
						result = false;
					}*/
					if ($(this).val().length == 0)
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$('#cart-phone-required').show();
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "number":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					{
						show_error = true;
						result = false;
					}
				});
				
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
			case "email":
				$(this).closest('div.row').find('input.required, textarea.required').each(function(){
					if (!isValidEmailAddress($(this).val()))
					{
						show_error = true;
						result = false;
					}
				});
				if (show_error && show_errors)
				{
					$(this).closest('div.row').find('.help-block-error').show();
					$(this).closest('div.form-group').find('.fa-check').hide();
					if (!is_focused) {
						$(this).focus();
						is_focused = true;
					}
				}
				else
					if (!show_error && show_checks)
					{
						$(this).closest('div.row').find('.help-block-error').hide();
						$(this).closest('div.form-group').find('.fa-check').show();
					}
				break;
		}
	});
	
	/*Дополнительная проверка email (если поле заполнено)*/
	var show_error = false;
	if ($(modal_id + ' input[name=email]').length > 0 && $(modal_id + ' input[name=email]').val().length > 0 && !isValidEmailAddress($(modal_id + ' input[name=email]').val())) {
		show_error = true;
		result = false;
	}
	if (show_error)
	{
		$(modal_id + ' input[name=email]').closest('div.row').find('.help-block-error').show();
		$(modal_id + ' input[name=email]').closest('div.form-group').find('.fa-check').hide();
		if (!is_focused) {
			$(modal_id + ' input[name=email]').focus();
			is_focused = true;
		}
	}
	else
		if ($(modal_id + ' input[name=email]').length > 0 && isValidEmailAddress($(modal_id + ' input[name=email]').val())) {
			$(modal_id + ' input[name=email]').closest('div.row').find('.help-block-error').hide();
			$(modal_id + ' input[name=email]').closest('div.form-group').find('.fa-check').show();
		}
	
	return result;
}

function confirmOrderProcess(callback){
	var confirmModal = 
		$('<div id="delModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">'+
			'<div class="modal-dialog">'+
				'<div class="modal-content">'+
					'<div class="modal-header">'+
						'<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>'+
						'<h4 class="modal-title">Оформление заказа</h4>'+
					'</div>'+
					'<div class="modal-body">С включенным VPN оплата картой не работает! Перед оформлением и оплатой убедитесь, что VPN отключён</div>'+
					'<div class="modal-footer">'+
						'<button type="button" class="btn btn-default" data-dismiss="modal" id="dismissButton">Назад</button>'+
						'<button type="button" class="btn btn-primary" id="okDel">Оплатить</button>'+
					'</div>'+
				'</div>'+
			'</div>'+
		'</div>');
	confirmModal.find('#okDel').click(function(event) {
		callback();
		confirmModal.modal('hide');
	});
	confirmModal.find('#dismissButton').click(function(event) {
		$('#save_order').removeClass('disabled');
		$('#save_order_spinner').addClass('hidden');
	});
	confirmModal.modal('show');     
};

$('#save_order').click(function(){
	var result = check_user_info_data(true, true);

	if (result){
				
		$('#save_order').addClass('disabled');
		$('#save_order_spinner').removeClass('hidden');
		
		var modificators = [];
		var modificators_count = [];
		$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
			if (parseInt($(this).val()) > 0)
			{
				modificators.push($(this).val());
				var ic = $(this).closest('div').find('input[name^=modificators_count]');
				if (ic.length > 0)
					modificators_count.push(ic.val());
				else
					modificators_count.push(1);
			}
		});
		
		$('input[name=order_modificators]').val(modificators.join(','));
		$('input[name=order_modificators_count]').val(modificators_count.join(','));
		
		
		if(window.Comagic && window.Comagic.addOfflineRequest) {
			//window.thatForm = $(this).closest('form');
			window.thatForm = $('#cart_form');
			var cData = {
				name: window.thatForm.find('input[name="name"]').val(),
				phone: window.thatForm.find('input[name="phone"]').val(),
				email: window.thatForm.find('input[name="email"]').val(),
				message: window.thatForm.find('textarea[name="comment"]').val(),
			};
			window.Comagic.addOfflineRequest(cData, function () {
				window.thatForm.submit();
			});
		}
		else {
			//$(this).closest('form').submit();
			$('#cart_form').submit();
		}
	}
	else
		return false;
});

$('#save_order_cart2').click(function(){
	$('#cart2-modal-buyer-info').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-next-step2').click(function(){
	var result = check_user_info_data_in_modal('#cart2-modal-buyer-info', true, true);
	if ($('#cart2-modal-buyer-info #phone').val().length < 10)
	{
		$('#cart2-modal-buyer-info #phone').focus();
		//$('#buy1click-phone-required').show();
		return false;
	}
	if (result) {
		$('#cart2-modal-buyer-info').modal('hide');
		$('#cart2-modal-delivery-settings').modal('show');
		$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	}
	return false;
});

$('#cart2-prev-step1').click(function(){
	$('#cart2-modal-delivery-settings').modal('hide');
	$('#cart2-modal-buyer-info').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-next-step3').click(function(){
	$('#cart2-modal-delivery-settings').modal('hide');
	$('#cart2-modal-installation-settings').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-prev-step2').click(function(){
	$('#cart2-modal-installation-settings').modal('hide');
	$('#cart2-modal-delivery-settings').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-next-step4').click(function(){
	$('#cart2-modal-installation-settings').modal('hide');
	$('#cart2-modal-payment-settings').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-prev-step3').click(function(){
	$('#cart2-modal-payment-settings').modal('hide');
	$('#cart2-modal-installation-settings').modal('show');
	$('.modal-backdrop').css('background-color', '#000').css('z-index', 1040);
	return false;
});

$('#cart2-next-step-finish').click(function(){
	$('#cart_form input[name=buyer_type]').val($('#cart2-modal-buyer-info input[name=buyer_type]').val());
	$('#cart_form input[name=name]').val($('#cart2-modal-buyer-info input[name=name]').val());
	$('#cart_form input[name=phone]').val($('#cart2-modal-buyer-info input[name=phone]').val());
	$('#cart_form input[name=phone2]').val($('#cart2-modal-buyer-info input[name=phone2]').val());
	$('#cart_form input[name=email]').val($('#cart2-modal-buyer-info input[name=email]').val());
	$('#cart_form input[name=yur_details]').val($('#cart2-modal-buyer-info textarea[name=yur_details]').val());
	$('#cart_form input[name=address]').val($('#cart2-modal-buyer-info textarea[name=address]').val());
	$('#cart_form input[name=suggestion_enabled]').val($('#cart2-modal-buyer-info input[name=suggestion_enabled]').val());
	$('#cart_form input[name=comment]').val($('#cart2-modal-buyer-info textarea[name=comment]').val());
	
	$('#cart_form input[name=delivery_id]').val($('#cart2-modal-delivery-settings input[name=delivery_id]:checked').val());
	$('#cart_form input[name=is_need_installation]').val($('#cart2-modal-installation-settings input[name=is_need_installation]').prop('checked') ? 1 : 0);
	$('#cart_form input[name=payment_method_id]').val($('#cart2-modal-payment-settings input[name=payment_method_id]:checked').val());
	
	if ($('#cart2-modal-delivery-settings input[name=mkad_kilometers]').length > 0)
		$('#cart_form input[name=mkad_kilometers]').val($('#cart2-modal-delivery-settings input[name=mkad_kilometers]').val());
	
	$('#cart2-next-step-finish').addClass('disabled');
	$('#cart2-next-step-finish_spinner').removeClass('hidden');
	
	var modificators = [];
	var modificators_count = [];
	$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
		if (parseInt($(this).val()) > 0)
		{
			modificators.push($(this).val());
			var ic = $(this).closest('div').find('input[name^=modificators_count]');
			if (ic.length > 0)
				modificators_count.push(ic.val());
			else
				modificators_count.push(1);
		}
	});
	
	$('input[name=order_modificators]').val(modificators.join(','));
	$('input[name=order_modificators_count]').val(modificators_count.join(','));
	
	
	if(window.Comagic && window.Comagic.addOfflineRequest) {
		//window.thatForm = $(this).closest('form');
		window.thatForm = $('#cart_form');
		var cData = {
			name: window.thatForm.find('input[name="name"]').val(),
			phone: window.thatForm.find('input[name="phone"]').val(),
			email: window.thatForm.find('input[name="email"]').val(),
			message: window.thatForm.find('textarea[name="comment"]').val(),
		};
		window.Comagic.addOfflineRequest(cData, function () {
			window.thatForm.submit();
		});
	}
	else {
		//$(this).closest('form').submit();
		$('#cart_form').submit();
	}
	
	return false;
});

if (mc_module_module == 'CartController') {
	$('#user-agree-personal-data').click(function(){
		if (this.checked) {
			$('#save_order').removeClass('disabled').prop('disabled','');
		} else {
			$('#save_order').addClass('disabled').prop('disabled','disabled');
		}
	});
}
if (mc_module_module == 'CartController2') {
	$('#user-agree-personal-data').click(function(){
		if (this.checked) {
			$('#save_order_cart2').removeClass('disabled').prop('disabled','');
		} else {
			$('#save_order_cart2').addClass('disabled').prop('disabled','disabled');
		}
	});
}

$('input[name=email]').focusout(function(){
	var show_error = false;
	if ($(this).val().length > 0 && !isValidEmailAddress($(this).val()))
		show_error = true;
	if (show_error)
	{
		$(this).closest('div.row').find('.help-block-error').show();
		$(this).closest('div.form-group').find('.fa-check').hide();
	}
	else
		if ($(this).val().length > 0 && isValidEmailAddress($(this).val())){
			$(this).closest('div.row').find('.help-block-error').hide();
			$(this).closest('div.form-group').find('.fa-check').show();
		}
});

$('.userdata input.required:visible, .userdata textarea.required:visible').focusout(function(){
	var type = $(this).attr('data-type');
	if (type.length == 0)
		type = "text";
	var show_error = false;
	switch(type){
		case "text":
			$(this).closest('div.row').find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.row').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.row').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "phone":
			$(this).closest('div.row').find('input.required, textarea.required').each(function(){
				//if ($(this).mask().length == 0)
				if ($(this).val().length == 0)
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.row').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.row').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "number":
			$(this).closest('div.row').find('input.required, textarea.required').each(function(){
				if ($(this).val().length == 0 || ($(this).attr('maxlength')>0 && $(this).attr('maxlength')>$(this).val().length))
					show_error = true;
			});
			
			if (show_error)
			{
				$(this).closest('div.row').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.row').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
		case "email":
			$(this).closest('div.row').find('input.required, textarea.required').each(function(){
				if (!isValidEmailAddress($(this).val()))
					show_error = true;
			});
			if (show_error)
			{
				$(this).closest('div.row').find('.help-block-error').show();
				$(this).closest('div.form-group').find('.fa-check').hide();
			}
			else
			{
				$(this).closest('div.row').find('.help-block-error').hide();
				$(this).closest('div.form-group').find('.fa-check').show();
			}
			break;
	}
});

$('#add-additional-phone').click(function(){
	$('#additional-phone').slideToggle(400);
	return false;
});

var amount_ajax_context;
function set_amount(variant_id, amount, update_bar){
	if (amount_ajax_context != null)
		amount_ajax_context.abort();
	
	/*if (update_bar) {
		bbar_update_prices();
	}*/
	
	amount_ajax_context = $.get(mc_cart_module_url + '?action=set_amount&variant_id=' + variant_id + '&amount=' + amount, function(data){
		if (data.success)
		{
			$('#cart').replaceWith(data.header);
			if (update_bar) {
				bbar_update(data.fbbar);
			}
			
			if ((!data.promocode_status && $('#remove-promocode').length > 0) || $('#promocode-error-msg').length > 0) {
				location.reload();
			}
		}
		amount_ajax_context = null;
		
		update_external_module_forms();
		if (data.total_products == 1 && !data.is_complect_containts) {
			$('#delivery-section input[name=delivery_id][value=9]').closest('div.delivery-select').show();
		} else {
			$('#delivery-section input[name=delivery_id][value=9]').closest('div.delivery-select').hide();
		}
	});
}

function update_price(){
	var input = $('.delivery-select input:checked');
	var total_cart_price = 0;
	var discount = parseFloat($('#user_discount').attr('data-value'));
	
	var is_need_installation = $('input[name=is_need_installation]').prop('checked');
	var is_need_gauger = $('input[name=is_need_gauger]').prop('checked');
	
	$('table.carttable tbody tr').each(function(){
		var td_count = $(this).find('td.carttable-count input');
		var td_price = $(this).find('td.carttable-priceone').attr('data-price-for-one-pcs');
		var td_price_additional = $(this).find('td.carttable-priceone').attr('data-price-additional');
		
		if (td_count.length > 0 && td_price.length > 0)
		{
			var input_count = parseFloat(td_count.val().length > 0 ? td_count.val() : 1);
			var input_price = parseFloat(td_price.replace(/ /g,''));
			var input_price_additional = parseFloat(td_price_additional.replace(/ /g,''));
			var priceall = input_count * input_price + input_price_additional;
			priceall = Math.round(priceall);
			var priceall_str = priceall.toString();
			priceall_str = priceall_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
			var td_priceall = $(this).find('td.carttable-priceall');
			td_priceall.html(priceall_str + ' ' + mc_main_currency_sign);
			total_cart_price += priceall;
		}
	});
	
	if (is_need_gauger && !is_need_installation) {
		total_cart_price += 2000;
	}
	
	var total_cart_price_w_discount = total_cart_price - total_cart_price * discount / 100;
	
	total_cart_price = Math.round(total_cart_price);
	var cart_total_price_str = total_cart_price.toString();
	cart_total_price_str = cart_total_price_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price').html(cart_total_price_str);
	
	total_cart_price_w_discount = Math.round(total_cart_price_w_discount);
	var cart_total_price_w_discount_str = total_cart_price_w_discount.toString();
	cart_total_price_w_discount_str = cart_total_price_w_discount_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price_w_discount').html(cart_total_price_w_discount_str);
	
	var cart_total_discount = Math.round(total_cart_price - total_cart_price_w_discount);
	var cart_total_discount_str = cart_total_discount.toString();
	cart_total_discount_str = cart_total_discount_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_discount').html(cart_total_discount_str);
	
	if (total_cart_price >= mc_cart_order_min_price){
		$('#not-enough-block').hide();
		$('.js-cart-block-not-enough').show();
	}
	else{
		$('#not-enough-block').show();
		$('.js-cart-block-not-enough').hide();
	}
	
	var total_price_w_modificators = total_cart_price_w_discount;
	
	$('.product-modifier input:checked, .product-modifier select option:selected').each(function(){
		var obj = $(this);
		var obj_type = obj.data('type');
		var obj_value = parseFloat(obj.data('value'));
		var obj_multi_buy = obj.data('multi-buy');
		var obj_multi_buy_value = 1;
		if (obj_multi_buy == 1)
			obj_multi_buy_value = parseInt($('input[name=modificators_count_'+obj.val()+']').val());
		
		if (obj_type == 'plus_fix_sum')
			total_price_w_modificators += obj_value * obj_multi_buy_value;
		if (obj_type == 'minus_fix_sum')
			total_price_w_modificators -= obj_value * obj_multi_buy_value;
		if (obj_type == 'plus_percent')
			total_price_w_modificators += total_cart_price_w_discount * obj_value * obj_multi_buy_value / 100;
		if (obj_type == 'minus_percent')
			total_price_w_modificators -= total_cart_price_w_discount * obj_value * obj_multi_buy_value / 100;
	});
	
	total_price_w_modificators = Math.round(total_price_w_modificators);
	var cart_total_price_w_modificators_str = total_price_w_modificators.toString();
	cart_total_price_w_modificators_str = cart_total_price_w_modificators_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#cart_total_price_w_modificators').html(cart_total_price_w_modificators_str);
	
	$('#delivery-section input[type=radio]').each(function(){
		
		//if ($(this).attr('data-ext-module-name').length > 0)
		//	return;
		
		var data_free = Math.round(parseFloat($(this).attr('data-free')));
		var data_price_enabled = $(this).attr('data-price-enabled');
		var data_price2_enabled = $(this).attr('data-price2-enabled');
		var data_price3_enabled = $(this).attr('data-price3-enabled');
		var data_price_from = Math.round(parseFloat($(this).attr('data-price-from')));
		var data_price2_from = Math.round(parseFloat($(this).attr('data-price2-from')));
		var data_price3_from = Math.round(parseFloat($(this).attr('data-price3-from')));
		var data_price_to = Math.round(parseFloat($(this).attr('data-price-to')));
		var data_price2_to = Math.round(parseFloat($(this).attr('data-price2-to')));
		var data_price3_to = Math.round(parseFloat($(this).attr('data-price3-to')));
		var data_price = Math.round(parseFloat($(this).attr('data-price')));
		var data_price2 = Math.round(parseFloat($(this).attr('data-price2')));
		var data_price3 = Math.round(parseFloat($(this).attr('data-price3')));
		var data_price_always = Math.round(parseFloat($(this).attr('data-price-always')));
		var data_separate = parseInt($(this).attr('data-separate'));
		if (data_separate == 1)
		{
			$(this).closest('div.radio').find('span.delivery-price').html('(Оплачивается отдельно)');
			$(this).attr('data-value', 0);
		}
		else {
			var data_price_value = data_price_always;
			
			if (data_price_enabled && data_price_from <= total_price_w_modificators && total_price_w_modificators <= data_price_to)
				data_price_value += data_price;
			else
				if (data_price2_enabled && data_price2_from <= total_price_w_modificators && total_price_w_modificators <= data_price2_to)
					data_price_value += data_price2;
				else
					if (data_price3_enabled && data_price3_from <= total_price_w_modificators && total_price_w_modificators <= data_price3_to)
						data_price_value += data_price3;
					
			if (data_price_value > 0) {
				var data_price_str = data_price_value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
				$(this).closest('div.radio').find('span.delivery-price').html('(' + data_price_str + ' ' + mc_main_currency_sign + ')');
				$(this).attr('data-value', data_price_value);
			} else {
				$(this).closest('div.radio').find('span.delivery-price').html('(Бесплатно)');
				$(this).attr('data-value', 0);
			}
		}
	});
	
	var delivery_price = parseFloat(input.attr('data-value'));
	if (input.data('deliverysettings'))
	{
		var dt_opt = $('#delivery-settings select[name=delivery_time] option:selected');
		if (dt_opt.length > 0)
			delivery_price += parseFloat(dt_opt.attr('data-price'));
	}
	if (input.length > 0 && input.attr('data-ext-module-name').length > 0) {
		var pa = parseFloat(input.attr('data-price-always'));
		delivery_price += pa;
	}
	var total_price = total_price_w_modificators + delivery_price;
	total_price = Math.round(total_price);
	
	var total_price_with_payment = total_price;
	var payment_type = $('#payment-section .payment-method.active div.radio').attr('data-type');
	var payment_val = parseFloat($('#payment-section .payment-method.active div.radio').attr('data-value'));
	if (payment_type == "plus_fix_sum"){
		total_price_with_payment += payment_val;
	}
	if (payment_type == "minus_fix_sum"){
		total_price_with_payment -= payment_val;
	}
	if (payment_type == "plus_percent"){
		total_price_with_payment *= 100/(100-payment_val);
	}
	if (payment_type == "minus_percent"){
		total_price_with_payment *= 100/(100+payment_val);
	}
	total_price_with_payment = Math.round(total_price_with_payment);
	
	if (isNaN(total_price_with_payment))
		total_price_with_payment = 0;
	var total_price_with_payment_str = total_price_with_payment.toString();
	total_price_with_payment_str = total_price_with_payment_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	$('#total_price_with_payment').html(total_price_with_payment_str);
	
	if ($('#total_price_with_payment_in_currency').length > 0) {
		//Отобразим в рублях
		total_price_with_payment_in_currency = Math.round(total_price_with_payment * mc_currency_rate_to);
		window.console.log('total_price_with_payment_in_currency=' + total_price_with_payment_in_currency);
		var total_price_with_payment_in_currency_str = total_price_with_payment_in_currency.toString();
		total_price_with_payment_in_currency_str = total_price_with_payment_in_currency_str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
		window.console.log('total_price_with_payment_in_currency_str=' + total_price_with_payment_in_currency_str);
		$('#total_price_with_payment_in_currency').html(total_price_with_payment_in_currency_str);
	}
	
	$("#fbbar").find(".js-fbbar_cart_total").text(total_price_with_payment_str);
	//$('#total_price_with_payment2').html(total_price_with_payment_str);
}

$('.product-modifier input, .product-modifier select').change(function(){
	var checked = $(this).prop('checked');
	var d = $(this).closest('div');
	if (checked){
		d.find('input[name^=modificators_count]').prop('disabled', false);
		d.find('button[data-type=minus]').prop('disabled', false);
		d.find('button[data-type=plus]').prop('disabled', false);
	}
	else{
		d.find('input[name^=modificators_count]').prop('disabled', true);
		d.find('button[data-type=minus]').prop('disabled', true);
		d.find('button[data-type=plus]').prop('disabled', true);
	}
	
	update_price();
});

$('.product-modifier button[data-type=minus]').click(function(){
	var i = $(this).closest('.input-group').find('input');
	if (i.val() > i.data('min'))
		i.val(parseInt(i.val()) - 1);
	update_price();
});

$('.product-modifier button[data-type=plus]').click(function(){
	var i = $(this).closest('.input-group').find('input');
	if (i.val() < i.data('max'))
		i.val(parseInt(i.val()) + 1);
	update_price();
});

$('.product-modifier input[name^=modificators_count]').bind('change keyup input click', function(){
	if (this.value.match(/[^0-9]/g)) {
		this.value = this.value.replace(/[^0-9]/g, '');
	}
	var min = parseInt($(this).data('min'));
	var max = parseInt($(this).data('max'));
	if (this.value < min)
		this.value = min;
	if (this.value > max)
		this.value = max;
	update_price();
});

function isValidEmailAddress(emailAddress) {
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	return pattern.test(emailAddress);
}
}